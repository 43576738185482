<template>
    <div class="performance-set">
        <div class="performance-set-top">
            <div class="performance-set-top-left">
                <el-switch
                    v-model="isOpen"
                    :disabled="isSwitchDisabled"
                    :width=40
                    active-color="#38bc9d"
                    inactive-color="#999999"
                    @change="setOpenStatus">
                </el-switch>
                <span class="performance-set-top-text">启用业绩计算</span>
                <i class="performance-set-top-tip">若不配置参数，业绩将计算给推荐者</i>
            </div>
            <div class="performance-set-top-right">
                <div class="performance-set-top-button" @click="handleAdd">
                    <i class="el-icon-circle-plus-outline"></i>
                    新增参数
                </div>
            </div>
        </div>
        <div class="performance-set-table">
            <el-table
                v-loading="loading"
                :data="tableData"
                border
                style="width: 100%"
                height="800"
                tooltip-effect="light">
                <el-table-column
                    prop="name"
                    align="center"
                    :show-overflow-tooltip="true"
                    label="参数名称">
                </el-table-column>
                <el-table-column
                    align="center"
                    label="默认用户">
                    <template slot-scope="scope">
                        <div>{{defaultUserMap[scope.row.defaultUser]}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="参数值">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 0">{{scope.row.value}}</div>
                        <div v-if="scope.row.type == 1">{{scope.row.value + '%'}}</div>
                        <div v-if="scope.row.type == 2">{{typeMap[scope.row.type]}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否支持填写多个"
                    align="center">
                    <template slot-scope="scope">
                        <div>{{scope.row.isMulti ? '是' : '否'}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否支持修改默认用户"
                    align="center"
                    width="165">
                    <template slot-scope="scope">
                        <div>{{scope.row.canModifyDefaultUser ? '是' : '否'}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否支持修改分成值"
                    align="center"
                    width="150">
                    <template slot-scope="scope">
                        <div>{{scope.row.canModifyPercentValue ? '是' : '否'}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="状态"
                    align="center">
                    <template slot-scope="scope">
                        <div>{{scope.row.status ? '启用' : '关闭'}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0" class="operation-column-btn enable" @click="handleEnableSetting(scope.row)">启用</span>
                        <span v-else class="operation-column-btn disable" @click="handleDisableSetting(scope.row)">关闭</span>
                        <i>|</i>
                        <span class="operation-column-btn edit" @click="handleEdit(scope.row)">编辑</span>
                        <i>|</i>
                        <span class="operation-column-btn delete" @click="doDelete(scope.row.name, scope.row.id)">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="performance-set-footer">
            猎必得 liebide.com
        </div>
        <add-param-dialog 
            ref="addParamDialog"
            v-on:refresh-list="handleRefreshList"
        ></add-param-dialog>
        <double-confirm
            ref="doubleConfirm"
        ></double-confirm>
    </div>
</template>

<script>
    import AddParamDialog from './component/add-param-dialog.vue';
    import DoubleConfirm from './component/double-confirm-dialog.vue';

    import performanceSetService from '#/js/service/performanceSetService.js';
    import userService from '#/js/service/userService.js';
    export default {
        name: "PerformanceSet",
        components: {
            AddParamDialog,
            DoubleConfirm
        },
        data() {
            return {
                loading: false,
                isSwitchDisabled: false,
                isOpen: false,
                tableData: [],
                defaultUserMap: {
                    0: '无',
                    1: '归属权判断',
                    2: '推荐人',
                    3: '职位发布者',
                    4: '简历创建者',
                    5: '客户BD',
                    6: '推荐接受者',
                },
                typeMap: {
                    0: '手动输入',
                    1: '比例',
                    2: 'BD分成比例'
                }
            }
        },
        computed: {
            isPerformanceSet() {
                return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('PerformanceSet');
            },
        },
        mounted() {
            // this.init();
            if(this.$store.state.user.userInfo.isAdministrator || this.isPerformanceSet){
                this.init();
            } else {
                shortTips("权限不足，请联系管理员");
                let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
                let _index = tabItems.findIndex(
                    (item) => item.path === "/performanceSet"
                );
                tabItems.splice(_index, 1);
                sessionStorage.setItem("tabItems", JSON.stringify(tabItems));
                setTimeout(() => {
                    location.href = "/Headhunting/#/";
                }, 2000);
            }
        },
        methods: {
            init() {
                this.loading = true;
                this.checkOpenStatus();
                performanceSetService.getSettingList()
                    .then(res =>{
                        this.loading = false;
                        this.tableData = res;
                    });
            },
            checkOpenStatus() {
                performanceSetService.checkOpenStatus()
                    .then(res =>{
                        if(res) {
                            this.isOpen = true;
                        }
                    })
            },
            setOpenStatus(isOpen) {
                performanceSetService.setOpenStatus({isOpen: isOpen ? 1 : 0})
                    .then(res =>{
                        this.isOpen = isOpen;
                        shortTips(isOpen ? '已启用业绩计算' : '已停用业绩计算');
                        userService.getSubject()
                        .then(res => {
                            this.$store.dispatch('setUserInfo', res);
                        }).catch(err =>{
                            console.log(err);
                        })
                    })
            },
            handleAdd() {
                this.$refs.addParamDialog.show();
            },
            handleEdit(item) {
                this.$refs.addParamDialog.show(item);
            },
            handleRefreshList() {
                this.init();
            },
            doDelete(name, deleteId) {
                this.$refs.doubleConfirm.show({
                    text: `确定要删除参数 "${name}" 吗？`,
                    confirmFn: () =>{ return this.handleDelete(deleteId); }
                });
            },
            handleDelete(deleteId) {
                return performanceSetService.deleteSetting({ id: deleteId })
                        .then(() =>{
                            shortTips('删除成功!');
                            this.handleRefreshList();
                        });
            },
            handleEnableSetting(item) {
                this.$refs.doubleConfirm.show({
                    text: `确定要启用参数 "${item.name}" 吗？`,
                    confirmFn: () =>{ return this.handleEditSettingStatus(item, 1); }
                });
            },
            handleDisableSetting(item) {
                this.$refs.doubleConfirm.show({
                    text: `确定要关闭参数 "${item.name}" 吗？`,
                    confirmFn: () =>{ return this.handleEditSettingStatus(item, 0); }
                });
            },
            handleEditSettingStatus(item, status) {
                return performanceSetService.editSettingStatus({ id: item.id, status: status })
                    .then(() =>{
                        shortTips(status == 1 ? '已启用' : '已关闭');
                        item.status = status;
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .performance-set {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        padding: 0 20px;
        height: 100%;
        background:url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
        &-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;
            padding: 0 20px;
            height: 48px;
            background-color: #fff;

            &-left {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &-text {
                font-size: 14px;
                color: #666;
                margin-left: 10px;
            }

            &-tip {
                font-size: 12px;
                color: #999;
                margin-left: 20px;
            }

            /deep/ .el-switch {
                &__core:before {
                    content: '关';
                    position: absolute;
                    top: 1px;
                    width: 16px;
                    height: 16px;
                    line-height: 16px;
                    text-align: center;
                    left: 100%;
                    margin-left: -18px;
                    font-size: 12px;
                    color: #fff;
                }

                &.is-checked {
                    .el-switch__core:before {
                        content: '开';
                        left: 1px;
                        margin-left: 0;
                    }
                }
            }

            &-button {
                width: 90px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                font-size: 14px;
                color: $primary;
                border: 1px solid $primary;
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    color: #fff;
                    background-color: $primary;
                }
            }
        }

        .performance-set-table {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            letter-spacing: 0px;
            height: 100%;

            .operation-column-btn {
                transition: .1s;
                cursor: pointer;
                user-select: none;

                & + i {
                    margin: 0 4px;
                    color: #ddd;
                }

                &:hover {
                    text-decoration: underline;
                }
                &.enable {
                    color: #4A90E2;
                    opacity: 1;

                    &:active {
                        opacity: 0.7;
                    }
                }

                &.disable {
                    color: #666;
                    opacity: 1;

                    &:active {
                        opacity: 0.7;
                    }
                }

                &.edit {
                    color: $primary;
                    opacity: 1;

                    &:active {
                        opacity: 0.7;
                    }
                }

                &.delete {
                    color: #FC7859;
                    opacity: 1;

                    &:active {
                        opacity: 0.7;
                    }
                }
            }

            /deep/ .el-table {
                background-color: transparent;

                th {
                    padding: 0;
                    height: 42px;
                    line-height: 42px;
                    background-color: #E8E8E8;
                    border-color: #CCC;
                    color: #666;
                }
            }
        }


        &-footer {
            height:45px;
            line-height: 45px;
            text-align: center;
            font-size: 12px;
            color: #999999;
        }
    }
</style>